import { EventEmitter, Injectable } from '@angular/core';
import { LocalStorageService } from '@core/services/local-storage.service';
import moment from 'moment';
import { ReviewerTimeEntryListModel } from '../../models/reviewer-time-entry-list.model';
import { LookUpDto, TimeEntriesCaseDto } from '@core/api-client';
import { FormatTimeDurationPipe } from '@shared/pipes/dates/format-time-duration.pipe';
import { TrackingService } from '@core/services/tracking.service';

@Injectable({
  providedIn: 'root',
})
export class TimeClockService {
  startTime?: Date;
  endTime?: Date;
  totalTime: string = '00:00';
  totalMinutes: number = 0;
  totalSeconds: number = 0;
  readonly secondsPerMinute = 60;

  timer: any;

  startTimeStorageKey = 'clock_start_time';
  endTimeStorageKey = 'clock_end_time';

  onTimeEnrtyAdded = new EventEmitter<void>();
  onAvailableCaseListChanged = new EventEmitter<void>();

  constructor(
    private readonly localStorage: LocalStorageService,
    private readonly formatTimeDurationPipe: FormatTimeDurationPipe,
    private readonly tracking: TrackingService,
  ) {
    const startTime = this.localStorage.get(this.startTimeStorageKey);
    const endTime = this.localStorage.get(this.endTimeStorageKey);
    if (startTime || endTime) {
      this.startTime = new Date(startTime!);

      if (endTime) {
        this.endTime = new Date(endTime);
      } else {
        this.start();
      }

      this.updateTotalTime();
    }

    // fire when another tab changes local storage
    window.addEventListener('storage', (event) => {
      if (event.key === this.startTimeStorageKey && event.newValue) {
        this.startTime = new Date(event.newValue!);
        this.start();
      }
      if (event.key === this.endTimeStorageKey && event.newValue) {
        this.reset();
      }
    });
  }

  canTimeOut() {
    return (
      !this.localStorage.get(this.startTimeStorageKey) &&
      !this.localStorage.get(this.endTimeStorageKey)
    );
  }

  start(): void {
    this.tracking.captureMessage('time_clock_start');
    if (!this.startTime) {
      this.startTime = new Date();
      this.localStorage.set(
        this.startTimeStorageKey,
        this.startTime.toISOString(),
      );
    }

    this.timer = setInterval(() => {
      this.updateTotalTime();
    }, 1000);
  }

  stop(): void {
    this.tracking.captureMessage('time_clock_stop');
    clearInterval(this.timer);
    this.endTime = new Date();
    this.updateTotalTime();
    this.localStorage.set(this.endTimeStorageKey, this.endTime.toISOString());
  }

  updateTotalTime(): void {
    const duration = moment.duration(
      moment(this.endTime || new Date()).diff(moment(this.startTime)),
    );
    this.totalSeconds = duration.asSeconds();
    this.totalMinutes = this.totalSeconds / this.secondsPerMinute;

    this.totalTime = this.formatTimeDurationPipe.transform(this.totalMinutes);
  }

  reset(): void {
    this.tracking.captureMessage('time_clock_reset');
    this.startTime = undefined;
    this.endTime = undefined;
    this.totalTime = '00:00';
    this.localStorage.remove(this.startTimeStorageKey);
    this.localStorage.remove(this.endTimeStorageKey);
  }

  getDefaultCaseAndReviewerType(
    timeEntries: ReviewerTimeEntryListModel[],
    cases: TimeEntriesCaseDto[],
    casesMap: {
      [key: number]: TimeEntriesCaseDto;
    },
  ): { caseId: number | null; reviewerTypeId: number | null } {
    if (timeEntries.length) {
      const lastEntry = timeEntries[0];
      const lastCase = casesMap[lastEntry.caseId];
      if (lastCase) {
        return {
          caseId: lastCase.id,
          reviewerTypeId: lastCase.reviewerTypes.some(
            (r) => r.id === lastEntry.reviewerTypeRoleId,
          )
            ? lastEntry.reviewerTypeRoleId
            : lastCase.reviewerTypes[0].id,
        };
      }
    }
    if (cases.length > 0) {
      const firstCase = cases[0];
      return {
        caseId: firstCase.id,
        reviewerTypeId:
          firstCase.reviewerTypes.length > 0
            ? firstCase.reviewerTypes[0].id
            : null,
      };
    }
    return { caseId: null, reviewerTypeId: null };
  }
}
