import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InternationalizationModule } from '@/internationalization/internationalization.module';
import { CoreModule } from '@core/core.module';
import { HttpTranslateLoaderFactory } from '@core/translations/HttpTranslateLoader';
import { UiNotificationsModule } from '@/ui-notifications/ui-notifications.module';
import { FormatTimeDurationPipe } from '@shared/pipes/dates/format-time-duration.pipe';
import { LoadingInterceptor } from '@core/interceptors/loading.interceptor';
import { ErrorCatchingInterceptor } from '@core/interceptors/error-catching.interceptor';
import { HeaderInterceptor } from '@core/interceptors/header.interceptor';
import { AuthorizeInterceptor } from '@core/interceptors/authorize.interceptor';
import { InactivityInterceptor } from '@core/interceptors/inactivity.interceptor';
import { ChatTokenInterceptor } from '@core/interceptors/chat-token.interceptor';
import * as Sentry from '@sentry/angular';
import { TrackingService } from '@core/services/tracking.service';
import { FormatDatePipe } from '@shared/pipes/dates/format-date.pipe';
import { DatePipe } from '@angular/common';
import { provideDialog } from '@core/services/dialogs';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    BrowserModule,
    CoreModule,
    InternationalizationModule.forRoot({ locale_id: 'en-US' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpTranslateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    UiNotificationsModule,
    ConfirmDialogModule,
  ],
  providers: [
    TrackingService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InactivityInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ChatTokenInterceptor,
      multi: true,
    },
    DatePipe,
    FormatDatePipe,
    FormatTimeDurationPipe,
    provideDialog(),
    ConfirmationService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
