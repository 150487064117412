import { UserSettingsClient, UserSettingsDto } from '../../../core/api-client';
import { ComponentStore } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { unwrap } from '../../../core/extensions';
import { switchMap, tap } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocalStorageService } from '../../../core/services/local-storage.service';

interface UserSettingsState extends UserSettingsDto {}

const MINUTES_BEFORE_TIMEOUT_KEY = 'minutes_before_timeout';

@Injectable({ providedIn: 'root' })
@UntilDestroy()
export class UserSettingsStore extends ComponentStore<UserSettingsState> {
  readonly minutesBeforeTimeOut$ = this.select(
    (state) => state.minutesBeforeTimeOut,
  );

  constructor(
    private readonly userSettingsClient: UserSettingsClient,
    private readonly localStorageService: LocalStorageService,
  ) {
    super({
      hasNotificationOptout: false,
      hasEnabledNotificationSound: false,
      minutesBeforeTimeOut: parseInt(
        localStorageService.get(MINUTES_BEFORE_TIMEOUT_KEY) ?? '15',
        10,
      ),
      unreadMessagesIntervalMinutes: 10,
    });
  }

  refresh = this.effect(($trigger) =>
    $trigger.pipe(
      switchMap(() =>
        this.userSettingsClient.getUserSettings().pipe(
          unwrap<UserSettingsDto>(),
          tap((response) => this.update(response)),
        ),
      ),
    ),
  );

  update = this.updater((state, userSettings: Partial<UserSettingsDto>) => {
    if (userSettings.minutesBeforeTimeOut) {
      this.localStorageService.set(
        MINUTES_BEFORE_TIMEOUT_KEY,
        userSettings.minutesBeforeTimeOut.toString(),
      );
    }
    return {
      ...state,
      ...userSettings,
    };
  });

  reset = this.updater(() => ({
    hasNotificationOptout: false,
    hasEnabledNotificationSound: false,
    minutesBeforeTimeOut: 15,
    unreadMessagesIntervalMinutes: 10,
  }));
}
