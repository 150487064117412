import { InjectionToken } from '@angular/core';
import { DialogService as PrimeDialogService } from 'primeng/dynamicdialog';
import { DialogService } from './dialog.service';

export const DIALOG_SERVICES = new InjectionToken<DialogService[]>(
  'DIALOG_SERVICES',
);

export const DIALOG_ID = new InjectionToken<string>('DIALOG_ID');

export function provideDialog(id: string = '') {
  return [
    {
      provide: DIALOG_ID,
      useValue: id,
    },
    {
      provide: PrimeDialogService,
    },
    {
      provide: DialogService,
    },
  ];
}
