import { Injectable } from '@angular/core';
import { FilterService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class FilterRegistrationService {
  constructor(private readonly filterService: FilterService) {}

  registerFilters() {
    this.filterService.register(
      'array-filter',
      (value: any[] | undefined, filter: string[] | undefined): boolean => {
        if (!filter || !filter.length) {
          return true;
        }
        if (!value) {
          return false;
        }
        return filter.some(
          (f) =>
            value.some((v) => v === f) ||
            value.some((v) => v.name === f) ||
            (!f && !value.length),
        );
      },
    );
    this.filterService.register(
      'ratings',
      (value: number | undefined, filter: (number[] | null)[]): boolean => {
        if (filter === undefined || filter === null || filter.length === 0) {
          return true;
        }

        for (const range of filter) {
          if (!value && range == null) {
            return true;
          }

          if (!value) {
            return false;
          }

          if (range === null) {
            continue;
          }

          if (value >= range[0] && value < range[1]) {
            return true;
          }
        }

        return false;
      },
    );
    this.filterService.register(
      'relativity-choices',
      (value: string | undefined, filter: number[]): boolean => {
        if (filter === undefined || filter === null || filter.length === 0) {
          return true;
        }

        if (!value) {
          return false;
        }

        const choices = value?.split(',').map((choice) => +choice.trim());
        return choices.some((choice) => filter.flat().includes(choice));
      },
    );
    this.filterService.register(
      'custom-contains',
      (value: string, filter: string[]): boolean => {
        if (filter === undefined || filter === null || filter.length === 0) {
          return true;
        }

        if (value === undefined || value === null) {
          return false;
        }

        return filter.every((f) => value.toString().includes(f.toString()));
      },
    );
  }
}
