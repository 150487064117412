import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';

const GLOBALLY_HANDLE_ERRORS = [500];

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  constructor(
    private readonly errorService: ErrorService,
  ) {
  }

  intercept(
    req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {
      // Unhandled internal server error
      if (GLOBALLY_HANDLE_ERRORS.includes(error.status)) {
        this.errorService.handleError(error);
      }
      return throwError(error);
    }));
  }
}
