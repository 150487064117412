import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Message, MessageService } from 'primeng/api';
import { DialogService } from '@core/services/dialogs';
import { AlertDialogComponent } from 'src/app/shared/components/alert-dialog/alert-dialog.component';
import { ErrorsDialogComponent } from '@shared/components/errors-dialog/errors-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class UiNotificationService {
  readonly toastKey = 'UiNotificationService';
  private readonly toastConfig: Partial<Message> = {
    key: this.toastKey,
    life: 5000,
  };

  constructor(
    private readonly messageService: MessageService,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
  ) {}

  public success(message: string, title = 'Success', styleClass = ''): void {
    this.messageService.add({
      ...this.toastConfig,
      key: this.toastKey,
      severity: 'success',
      styleClass,
      detail: message,
      summary: title,
    });
  }

  public warning(message: string, title = 'Warning'): void {
    this.messageService.add({
      ...this.toastConfig,
      key: this.toastKey,
      severity: 'warning',
      detail: message,
      summary: title,
    });
  }

  public validationError(): void {
    this.errorToast(
      this.translateService.instant('developer.yourSubmissionHasErrors'),
    );
  }

  public errorToast(message: string, title = 'Error'): void {
    this.messageService.add({
      ...this.toastConfig,
      key: this.toastKey,
      severity: 'error',
      detail: message,
      summary: title,
    });
  }

  formatError(error: any) {
    let message;
    if (typeof error === 'function') {
      message = error();
    } else if (
      typeof error === 'object' &&
      (error['details'] ||
        error['title'] ||
        error['message'] ||
        error['statusText'])
    ) {
      message =
        error['details'] ??
        error['title'] ??
        error['message'] ??
        error['statusText'];
    } else if (typeof error === 'string') {
      message = error;
    } else {
      message = 'common.unknownErrorOccurred';
    }
    return this.translateService.instant(message);
  }

  public error(
    error: any,
    params?: {
      onSubmit?: () => void;
      onClose?: () => void;
      size?: string;
    },
  ): void {
    this.dialogService.open(AlertDialogComponent, {
      data: {
        message: this.formatError(error),
        onSubmit: params?.onSubmit,
        onClose: params?.onClose,
      },
      styleClass: (params?.size ?? 'tw-dialog-sm') + ' tw-break-words',
    });
  }

  public alert(
    message: string,
    options: { onSubmit?: () => void; width?: string } = {},
  ): void {
    this.dialogService.open(AlertDialogComponent, {
      data: {
        message,
        onSubmit: options.onSubmit,
        width: options.width,
      },
      styleClass: 'tw-dialog-sm',
    });
  }

  public errors(
    message: string,
    errors: string[],
    options: { width?: string } = {},
  ): void {
    this.dialogService.open(ErrorsDialogComponent, {
      data: {
        message,
        errors,
        width: options.width,
      },
      styleClass: 'tw-dialog-sm',
    });
  }

  appliedNotification({ isPendingStart }: { isPendingStart: boolean }) {
    if (isPendingStart) {
      this.success('You are now confirmed for this project.');
    } else {
      this.success('You are now active on this project.');
    }
  }
}
