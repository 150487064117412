import {
  inject,
  Inject,
  Injectable,
  OnDestroy,
  Optional,
  Type,
} from '@angular/core';
import {
  DialogService as PrimeDialogService,
  DynamicDialogConfig,
} from 'primeng/dynamicdialog';

import { DialogServicesRegistry } from '@core/services/dialogs/dialog-services.registry';

@Injectable({
  providedIn: 'any',
})
export class DialogService implements OnDestroy {
  readonly service = inject(PrimeDialogService);

  constructor(private readonly dialogServicesRegistry: DialogServicesRegistry) {
    this.dialogServicesRegistry.register(this);
  }

  ngOnDestroy(): void {
    this.dialogServicesRegistry.unregister(this);
  }

  open(componentType: Type<any>, config: DynamicDialogConfig) {
    return this.service.open(componentType, config);
  }

  closeAll() {
    this.service.dialogComponentRefMap.forEach((componentRef) => {
      componentRef.instance.close();
    });
  }
}
