import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ChatTokenStore } from '@/chat/stores/chat-token.store';

@Injectable({
  providedIn: 'root',
})
export class ChatTokenInterceptor implements HttpInterceptor {
  constructor(
    private readonly chatTokenStore: ChatTokenStore,
  ) {
  }

  intercept(
    req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.chatTokenStore.token$.pipe(
      take(1),
      switchMap((token) => {
        const headerToken = req.headers.get('AzureToken');
        const requireToken = headerToken != null;
        if (requireToken && !headerToken && token) {
          req = req.clone({
            setHeaders: {
              'AzureToken': token,
            },
          });
        }
        return next.handle(req);
      },
      ));
  }
}
