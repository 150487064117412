import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, share, switchMap } from 'rxjs/operators';
import { AccountResultDto } from '../api-client';
import { JWTTokenService } from '../services/jwt-token.service';
import { isApiRequest, isLocalizationRequest } from './interceptors.helper';
import { InactivityService } from '@core/services/inactivity.service';
import { TrackingService } from '@core/services/tracking.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeInterceptor implements HttpInterceptor {
  private static refreshTokenObservable?: Observable<AccountResultDto>;

  constructor(
    private readonly jwtService: JWTTokenService,
    private readonly inactivityService: InactivityService,
    private readonly tracking: TrackingService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this.processRequestWithToken(req, next);
  }

  // Checks if there is an access_token available in the authorize service
  // and adds it to the request in case it's targeted at the same origin as the
  // single page application.
  private processRequestWithToken(req: HttpRequest<any>, next: HttpHandler) {
    if (
      this.jwtService.jwtToken &&
      isApiRequest(req) &&
      !isLocalizationRequest(req) &&
      !req.url.includes('logout')
    ) {
      if (
        this.jwtService.isTokenExpired() &&
        !req.url.includes('refresh-token')
      ) {
        // Call the API and get the token using the refresh Token
        if (!AuthorizeInterceptor.refreshTokenObservable) {
          AuthorizeInterceptor.refreshTokenObservable = this.jwtService
            .refreshToken()
            .pipe(share());
        }
        return AuthorizeInterceptor.refreshTokenObservable!.pipe(
          catchError((err) => {
            if (err?.message === 'Session has expired.') {
              this.tracking.captureMessage('session_expired');
            } else {
              this.tracking.captureException(err, {
                extra: {
                  message: 'Error refreshing token',
                },
              });
            }
            this.inactivityService.recordInactive();
            return EMPTY;
          }),
          switchMap(() => {
            AuthorizeInterceptor.refreshTokenObservable = undefined;
            req = req.clone({
              setHeaders: {
                Authorization: `Bearer ${this.jwtService.jwtToken}`,
              },
            });
            return next.handle(req);
          }),
        );
      }
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.jwtService.jwtToken}`,
        },
      });
    }

    return next.handle(req);
  }
}
