import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';
import { AccountClient } from '../api-client';
import { JWTTokenService } from './jwt-token.service';

@Injectable({
  providedIn: 'root',
})
export class LogOutService {
  constructor(
    private readonly router: Router,
    private readonly accountClient: AccountClient,
    private readonly jwtTokenService: JWTTokenService,
  ) {}

  async logout(next?: string) {
    this.accountClient.logout().subscribe(async () => {
      this.jwtTokenService.logout();
      await this.router.navigate(['/', 'auth', 'login'], {
        replaceUrl: true,
        queryParams: {
          next,
        },
      });
    });
  }
}
