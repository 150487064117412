import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(
    private readonly loadingService: LoadingService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return defer(() => {
      this.loadingService.start();
      return next.handle(request)
        .pipe(catchError((err) => {
          this.loadingService.finish();
          throw err;
        }))
        .pipe(
          finalize(() => {
            this.loadingService.finish();
          }),
        );
    });
  }
}
